import React from "react";
import CustomTitle from "../components/CustomTitle";
import "./careers.css";
import { useLocation, Link } from "react-router-dom";
export default function Contact() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const helmetDescriptions = {
    titleName:
      "Career Openings at Kangaroo Care Fertility Bangalore | Apply Today",
    Description:
      "Check out th career opportunities available for various positions at Kangaroo Care Fertility Hospital Bangalore. Apply Now.!",
  };
  return (
    <>
      <section className="carrer_wrapper">
        <div className="carrer_container">
          <h1>Join Our Team</h1>

          <div className="carrer_item">
            <div>
              <p>
                At Kangaroo Care Fertility Hospital, we are committed to
                building a passionate team of professionals dedicated to
                transforming lives through exceptional fertility care. We offer
                a dynamic, supportive work environment fostering growth,
                learning, and innovation. Whether you are a seasoned expert or
                just starting your career in reproductive healthcare, you’ll
                find opportunities to enhance your skills, work alongside top
                fertility specialists, and contribute to life-changing
                treatments.
              </p>
              <p>
                Our culture prioritizes collaboration, professional development,
                and patient-centric care. By joining Kangaroo Care, you will be
                part of a hospital that values compassion, cutting-edge
                technology, and excellence in fertility treatment. Ready to make
                a difference? Explore our career opportunities and join a
                mission that creates families and fulfils dreams.
              </p>
            </div>
            <section class="form_wrapper">
              <form
                method="POST"
                enctype="multipart/form-data"
                action="https://digikit.in/forms/kangaroocarefertility/careear/send.php"
                class="form"
              >
                <div>
                  <input type="text" name="name" placeholder="Name" required />
                </div>
                <div>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    required
                  />
                </div>

                <div>
                  <input
                    type="tel"
                    pattern="^[6-9]\d{9}$"
                    name="phone"
                    placeholder="Your Phone Number"
                    required
                  />
                </div>

                <div>
                  <div className="input_logo">
                    <textarea
                      id="message"
                      name="message"
                      placeholder="Message"
                    ></textarea>
                  </div>
                </div>

                <div>
                  <input
                    type="file"
                    id="myfile"
                    name="myfile"
                    data-max-size="100"
                    accept="application/pdf"
                    required
                  />
                </div>

                <div class="form_button">
                  <input type="submit" value="Submit Form" />
                </div>
              </form>
            </section>
          </div>
        </div>
      </section>
    </>
  );
}
