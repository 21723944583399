import React from "react";
import "./events.css";
import blog1 from "../assets/img/media/blogs/blog1.jpg";
import { useLocation, Link } from "react-router-dom";
export default function Events() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      {/* <div className="py-md-5" style={{ backgroundImage: "url(" + blogHeader + ")", backgroundPosition: 'center', backgroundSize: 'cover', height: '400px' }}>
        <div className=" text-center py-5">
            <h2 style={{ color: 'white', position: "relative", top: "100px" }}>| Events |</h2>
        </div>
    </div> */}
      <br />
      {/* <h6 className="offset-1 my-4" style={{ textAlign: "left", color: "black" }}>HOME &gt; MEDIA &gt; EVENTS</h6> */}

      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Media
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Events
          </li>
        </ol>
      </nav>
      {/* ---------------events---------------- */}

      <div className="container events">
        <div className="row">
          <div className="col-md-4 mb-3 mb-md-5 bg-light">
            <div className="card-img events-img events-container">
              <img src={blog1} alt="..." />
              <div className="events-overlay">
                <div className="events-text">Event Description</div>
              </div>
              <div className="card-title text-center py-2">
                <h6>Event title</h6>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img events-img events-container">
              <img src={blog1} alt="..." />
              <div className="events-overlay">
                <div className="events-text">Event Description</div>
              </div>
              <div className="card-title text-center py-2">
                <h6>Event title</h6>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img events-img events-container">
              <img src={blog1} alt="..." />
              <div className="events-overlay">
                <div className="events-text">Event Description</div>
              </div>
              <div className="card-title text-center py-2">
                <h6>Event title</h6>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img events-img events-container">
              <img src={blog1} alt="..." />
              <div className="events-overlay">
                <div className="events-text">Event Description</div>
              </div>
              <div className="card-title text-center py-2">
                <h6>Event title</h6>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img events-img events-container">
              <img src={blog1} alt="..." />
              <div className="events-overlay">
                <div className="events-text">Event Description</div>
              </div>
              <div className="card-title text-center py-2">
                <h6>Event title</h6>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img events-img events-container">
              <img src={blog1} alt="..." />
              <div className="events-overlay">
                <div className="events-text">Event Description</div>
              </div>
              <div className="card-title text-center py-2">
                <h6>Event title</h6>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img events-img events-container">
              <img src={blog1} alt="..." />
              <div className="events-overlay">
                <div className="events-text">Event Description</div>
              </div>
              <div className="card-title text-center py-2">
                <h6>Event title</h6>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img events-img events-container">
              <img src={blog1} alt="..." />
              <div className="events-overlay">
                <div className="events-text">Event Description</div>
              </div>
              <div className="card-title text-center py-2">
                <h6>Event title</h6>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img events-img events-container">
              <img src={blog1} alt="..." />
              <div className="events-overlay">
                <div className="events-text">Event Description</div>
              </div>
              <div className="card-title text-center py-2">
                <h6>Event title</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
