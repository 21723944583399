import React from "react";
import CustomTitle from "../CustomTitle";
import "./donor.css";
import { useLocation, Link } from "react-router-dom";
export default function Donor() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const helmetDescriptions = {
    titleName:
      "Sperm Donor Program for Fertility Treatment | Fertility Center Bangalore",
    Description:
      "Discover a range of donor options tailored to your fertility needs at Kangaroo Care Fertility. Start your path to parenthood with confidence.",
  };

  const donarSchema = `<script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What is Donor program?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Also known as third party reproduction is a ray of hope for childless couples who are unable to have their biological child. The Donor program may vary depending on the health problems of the infertile couple, from egg donation, and sperm donation to embryo donation."
      }
    },{
      "@type": "Question",
      "name": "How long does IVF take with Donor eggs?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Steps for donor Egg Recipient Process. A cycle takes 3 - 6 months to complete from initial screening to the actual retrieval and embryo transfer, depending on the type of donor oocyte program you choose."
      }
    },{
      "@type": "Question",
      "name": "How many eggs do they take when you donate?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The number of eggs that are retrieved during a cycle is usually somewhere between 10 and 20."
      }
    },{
      "@type": "Question",
      "name": "How successful is IVF with Donor sperm?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "IVF with Donor sperm typically has a higher success rate, but when comparing one cycle of IVF with a course of donor sperm inseminations the results are often not dissimilar."
      }
    },{
      "@type": "Question",
      "name": "What's the difference between Donor eggs and Donor embryos?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Egg donation uses unfertilized eggs which means you can use your partner's sperm, whereas embryo donation uses already created embryos ready to go into the uterus without using any sperm."
      }
    }]
  }
  </script>


  `;
  return (
    <div>
      <CustomTitle
        href="https://kangaroocarefertility.com/treatments/donor"
        customValue={helmetDescriptions}
      />
      <iframe srcDoc={donarSchema} title="schema" width="0" height="0" />
      <div className="main-content container mt-2">
        <nav aria-label="breadcrumb" className="mb-5">
          <ol className="breadcrumb">
            <li className="breadcrumb-item text-specifc-color">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item text-specifc-color">
              <Link href="/treatments/donor">Treatments</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Donor
            </li>
          </ol>
        </nav>
      </div>

      {/* -------main content------------ */}

      {/* -----------------------------what is portion -------------------- */}
      <div className="container-fluid service my-5">
        <div className="row justify-content-center">
          <div className="col-md-6 mx-2 d-none d-sm-block">
            <div className="mb-2" id="service-left-img-don"></div>
          </div>
          <div className="col-md-5 mb-2 mx-2" id="service-content-card">
            <h1 className="my-4 text-center text-blue">Donor</h1>
            <hr />
            <p>
              <i className="fas fa-check-circle me-2"></i>The Donor program may
              vary depending on the health problems of the infertile couple,
              from egg donation, and sperm donation to embryo donation.
            </p>
          </div>
        </div>

        {/* -----------------------section2 content process--------------------- */}

        <div className="row justify-content-center my-5">
          <div className="col-md-8 mx-2" id="service-content2-card">
            <div className="mb-2 p-4">
              <h2 className="text-center text-blue">Sperm Donors</h2>
              <p>
                In the sperm donation program, a Donor sperm is used instead of
                the male partner’s sperm for assisted reproduction.
              </p>
              <h2 className="text-blue">Why Sperm Donor Program?</h2>

              <p>
                <i className="fas fa-check-circle me-2"></i>
                <strong>Azoospermia in the male partner </strong> – no sperms in
                the ejaculate.
              </p>
              <p>
                <i className="fas fa-check-circle me-2"></i>
                <strong>Severe male infertility </strong> - where men do not
                want sperm retrieval procedures or when no sperms were retrieved
                even after the said procedures.
              </p>
              <p>
                <i className="fas fa-check-circle me-2"></i>Genetic disorders
                with a known risk of transmitting it to the offsprings.
              </p>
              <p>
                <i className="fas fa-check-circle me-2"></i>Couples who can not
                afford IVF/ICSI, can use Donor sperms for IUI. <br />
                Recurrent miscarriages
              </p>

              <h2 className="text-blue">
                Where do you get the Donor sperm from?
              </h2>
              <br />
              <p>
                <i className="fas fa-check-circle me-2"></i>
                The Donor sperms are obtained from semen banks. Semen banks are
                commercial centres which store various semen samples which can
                be used for assisted reproduction. Semen samples are collected
                from selected professional donors who are screened for various
                parameters. All semen banks have to adhere to ICMR (Indian
                Council of Medical Research) rules and regulations and as per
                ICMR norms semen Donor program should be anonymous at all times.
              </p>

              {/* <p><i className="fas fa-check-circle me-2"></i><strong>Egg Donation Program </strong> - Under the egg donation program, eggs/ oocytes are collected from a selected egg/ oocyte Donor (anonymous) and subjected to IVF/ICSI using the male partner’s sperm and later embryos are transferred to the female partner’s uterus.</p>
                     <p><i className="fas fa-check-circle me-2"></i><strong>Egg Donor Program? </strong> - The egg Donor program is suggested in the case of the following conditions.</p>
                     <p><i className="fas fa-check-circle me-2"></i><strong>Where do you get the Donor eggs from? </strong> - Egg Donors are treated in our own clinic and are chosen after a series of blood tests. We have to strictly follow the ICMR norms while choosing and treating egg Donors. The Donor will remain anonymous.</p>
                   */}
            </div>
          </div>
          <div
            className="col-md-3 mb-2 mx-2 p-4 card  d-none d-md-block"
            id="service-link-card"
          >
            <h2 className="text-blue">Important Links</h2>
            <br />
            <ul className="ms-5">
              <li>
                <Link to="/treatments/ovulation-induction">
                  <span className="hand-link">&#9758;</span> &nbsp; Ovulation
                  Induction
                </Link>
              </li>
              <li>
                <Link to="/treatments/intrauterine">
                  <span className="hand-link">&#9758;</span> &nbsp; Intrauterine
                  Insemination (IUI)
                </Link>
              </li>
              <li>
                <Link to="/treatments/invitro-fertilization">
                  <span className="hand-link">&#9758;</span> &nbsp; In Vitro
                  Fertilization
                </Link>
              </li>
              <li>
                <Link to="/treatments/intracytoplasmic">
                  <span className="hand-link">&#9758;</span> &nbsp;
                  Intracytoplasmic Sperm Injection{" "}
                </Link>
              </li>
              <li>
                <Link to="/treatments/genetic-counselling-and-testing">
                  <span className="hand-link">&#9758;</span> &nbsp; Genetic
                  Counselling and Testing
                </Link>
              </li>
              <li>
                <Link to="/treatments/varicocele">
                  <span className="hand-link">&#9758;</span> &nbsp; Varicocele
                </Link>
              </li>
              <li>
                <Link to="/treatments/tesa-pesa">
                  <span className="hand-link">&#9758;</span> &nbsp; TESA/ PESA
                </Link>
              </li>
              <li>
                <Link to="/treatments/fertility-preservation">
                  <span className="hand-link">&#9758;</span> &nbsp; Fertility
                  Preservation
                </Link>
              </li>
              <li>
                <Link to="/treatments/Donor">
                  <span className="hand-link">&#9758;</span> &nbsp; Donor
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container remove-bullet">
        <div className="row d-flex justify-content-center">
          <div className="col-md-10 p-4 the-3-service-card ">
            <h2 className="text-center text-blue">Egg Donation </h2>
            <p>
              <i className="fas fa-check-circle me-2"></i>
              Under the egg donation program, eggs/ oocytes are collected from a
              selected egg/ oocyte Donor (anonymous) and subjected to IVF/ICSI
              using the male partner’s sperm and later embryos are transferred
              to the female partner’s uterus.
            </p>
            <h2 className="text-blue">Why Egg Donor?</h2>
            <p>
              <i className="fas fa-check-circle me-2"></i>
              The egg donor program is suggested in the case of the following
              conditions:
              <ul>
                <li>
                  • Severe female factor infertility like severe endometriosis,
                  big ovarian cysts, ovarian cancers
                </li>
                <li>• PCOD</li>

                <li>• Premature ovarian failures</li>
                <li>• Anti-ovarian antibodies</li>
                <li>• Recurrent IVF failure</li>
              </ul>
            </p>
            <h2 className="text-blue">Where do you get the Donor eggs from?</h2>
            <p>
              <i className="fas fa-check-circle me-2"></i>
              Egg donors are treated in our own clinic and are chosen after a
              series of blood tests. We have to strictly follow the ICMR norms
              while choosing and treating egg donors. The Donor will remain
              anonymous.
            </p>
          </div>
        </div>
      </div>

      <div className="container mt-3 remove-bullet">
        <div className="row d-flex justify-content-center">
          <div className="col-md-10 p-4 the-3-service-card ">
            <h2 className="text-center text-blue">Embryo Donation </h2>
            <p>
              <i className="fas fa-check-circle me-2"></i>
              This procedure is similar to egg donation. Instead of borrowing
              the egg and the recipient’s male partner’s sperm for
              fertilization, both the egg and the sperm are derived from Donors.
            </p>
            <h2 className="text-blue">Why Embryo Donation?</h2>
            <p>
              <i className="fas fa-check-circle me-2"></i>
              There are selected groups of patients to whom embryo donation is
              recommended:
              <ul>
                <li>• When both the partners are infertile.</li>
                <li>
                  • Couples who are at a high risk of passing on genetic
                  disorders to their offspring
                </li>

                <li>• Women with recurrent IVF failures</li>
              </ul>
            </p>
          </div>
        </div>
      </div>

      {/* -------------------------advantage cards---------------- */}

      {/* <div className="container-fluid my-4">
        <div className="row justify-content-center" id="scards">
          <h2 className="text-center">Advantages of Ovulation Induction</h2>
          <br />
          <br />
          <div className="col-md-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              <img src={logo} alt="..." />
              <p>
                <strong>Affordable</strong>
              </p>
            </div>
            <p>
              Ovulation Induction involves oral medications and sometimes
              injections and thus is not expensive.
            </p>
          </div>
          <div className="col-md-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              <img src={logo} alt="..." />
              <p>
                <strong>Non-invasive</strong>
              </p>
            </div>
            <p>It does not involve any invasive procedures.</p>
          </div>
          <div className="col-md-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              <img src={logo} alt="..." />
              <p>
                <strong>Suitability</strong>
              </p>
            </div>
            <p>
              It is most suitable in women with PCOS, anovulation, mild-moderate
              endometriosis, unexplained infertility.
            </p>
          </div>
        </div>
      </div> */}

      {/* ---------------------------faq------------------------ */}

      <div className="accordion container-fluid my-5 " id="accordionExample">
        <h2 className="text-center text-blue">FAQs</h2>
        <br />
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              • What is Donor program?
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              Also known as third party reproduction is a ray of hope for
              childless couples who are unable to have their biological child.
              The Donor program may vary depending on the health problems of the
              infertile couple, from egg donation, and sperm donation to embryo
              donation.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              • How long does IVF take with Donor eggs?
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              Steps for donor Egg Recipient Process. A cycle takes 3 - 6 months
              to complete from initial screening to the actual retrieval and
              embryo transfer, depending on the type of donor oocyte program you
              choose.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              • How many eggs do they take when you donate?
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              The number of eggs that are retrieved during a cycle is usually
              somewhere between 10 and 20.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFour">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              • How successful is IVF with Donor sperm?
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              IVF with Donor sperm typically has a higher success rate, but when
              comparing one cycle of IVF with a course of donor sperm
              inseminations the results are often not dissimilar.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFive">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              • What's the difference between Donor eggs and Donor embryos?
            </button>
          </h2>
          <div
            id="collapseFive"
            className="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              Egg donation uses unfertilised eggs which means you can use your
              partner's sperm, whereas embryo donation uses already created
              embryos ready to go into the uterus without using any sperm.
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light ">
        <div className="container">
          <div className="row">
            <div className="col text-center my-sm-5 my-3">
              <h2 className="heading2">
                {" "}
                Why choose Kangaroo Care Fertility for the sperm donor centre in
                Bangalore?
              </h2>
              <p>
                Kangaroo Care Fertility is considered the best egg donor/sperm
                donation centre in Bangalore. We have an advanced sperm donor
                bank. Sperm donation is the process of collecting semen and
                donating it.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light ">
        <div className="container">
          <div className="row">
            <div className="col text-center my-sm-5 my-3">
              <h2 className="heading2">Sperm donor center in Bangalore</h2>
              <p>
                We are partnered with several reliable and reputed sperm banks
                to source high-quality donor samples. We match the samples
                carefully with the patients based on physical characteristics
                and blood type for optimum results.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
