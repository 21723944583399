import React from "react";
import "./image.css";

import images from "./images.json";
import { useLocation, Link } from "react-router-dom";
import CustomTitle from "../CustomTitle";
import ImageShow from "../../UI/ImageShow/ImageShow";

export default function Image() {
  const [show, setShow] = React.useState(null);

  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  console.log(images);

  // const images = array.map((key, index) => {
  //   return (
  //     <div className="col-md-4 mb-3 mb-md-5">
  //       <div className="card-img" id="card-img">
  //         {/* Add Your Image here from database */}
  //         <img
  //           src={require(`./images/${key.image}.jpg`)}
  //           src={key.image}
  //           alt="..."
  //         />
  //         <div className="card-title text-center py-2">
  //           {/* Add your Image text from database */}
  //           <span>Lorem</span>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // });
  const helmetDescriptions = {
    titleName: "Image Gallery | Kangaroo Care Fertility",
    Description:
      " View our photo Gallery to see the hospital environment and services.",
  };

  function hanldleImgClick(img) {
    // let imgPath = `${path}${img}`;
    setShow(img);
  }
  return (
    <>
      {show && (
        <ImageShow show={show} setShow={setShow} customPath="/images/" />
      )}

      <CustomTitle
        href="https://kangaroocarefertility.com/gallery/images"
        customValue={helmetDescriptions}
      />
      {/* <div id="banner-logo">
        <p className="text-center" id="banner-logo-text">
          Photo Gallery of Kangaroo <br /> Care Hospital
        </p>
      </div> */}
      {/* <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Gallery
          </li>
        </ol>
      </nav> */}
      <h1 className="h1-top-text mb-3 image-h1-top-text">
        Photo Gallery of Kangaroo Care Fertility
      </h1>
      <div className="container">
        <div className="row">
          {/* <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img src={img1} alt="..." />
              <div className="card-title text-center py-2">
               
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img src={img21} alt="..." />
              <div className="card-title text-center py-2">
               
              </div>
            </div>
          </div> */}

          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img
                src="/images/event-20.jpg"
                alt="..."
                onClick={() => hanldleImgClick("event-20.jpg")}
              />
              <div className="card-title text-center py-2"></div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img
                src="/images/img_front.jpg"
                alt="..."
                onClick={() => hanldleImgClick("img_front.jpg")}
              />
              <div className="card-title text-center py-2">
                {/* <span>Lorem</span> */}
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img
                src="/images/event-211.jpg"
                alt="..."
                onClick={() => hanldleImgClick("event-211.jpg")}
              />
              <div className="card-title text-center py-2">
                {/* <span>Lorem</span> */}
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img
                src="/images/event-212.jpg"
                alt="..."
                onClick={() => hanldleImgClick("event-212.jpg")}
              />
              <div className="card-title text-center py-2">
                {/* <span>Lorem</span> */}
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img
                src="/images/event-5.jpg"
                alt="..."
                onClick={() => hanldleImgClick("event-5.jpg")}
              />
              <div className="card-title text-center py-2">
                {/* <span>Lorem</span> */}
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img
                src="/images/AO5A0426_b.jpg"
                alt="..."
                onClick={() => hanldleImgClick("AO5A0426_b.jpg")}
              />
              <div className="card-title text-center py-2">
                {/* <span>Lorem</span> */}
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img
                src="/images/img_coun.jpg"
                alt="..."
                onClick={() => hanldleImgClick("img_coun.jpg")}
              />
              <div className="card-title text-center py-2">
                {/* <span>Lorem</span> */}
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img
                src="/images/AO5A0436.jpg"
                alt="..."
                onClick={() => hanldleImgClick("AO5A0436.jpg")}
              />
              <div className="card-title text-center py-2">
                {/* <span>Lorem</span> */}
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img
                src="/images/img_nursing.jpg"
                alt="..."
                onClick={() => hanldleImgClick("img_nursing.jpg")}
              />
              <div className="card-title text-center py-2">
                {/* <span>Lorem</span> */}
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img
                src="/images/AO5A0434.jpg"
                alt="..."
                onClick={() => hanldleImgClick("AO5A0434.jpg")}
              />
              <div className="card-title text-center py-2">
                {/* <span>Lorem</span> */}
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img
                src="/images/AO5A0431.jpg"
                alt="..."
                onClick={() => hanldleImgClick("AO5A0431.jpg")}
              />
              <div className="card-title text-center py-2">
                {/* <span>Lorem</span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

{
  /*
   <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img src={img7} alt="..." />
              <div className="card-title text-center py-2">
                
              </div>
            </div>
          </div>
      <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img src={img6} alt="..." />
              <div className="card-title text-center py-2">
              
                </div>
                </div>
              </div>
  <div className="col-md-4 mb-3 mb-md-5">
  <div className="card-img" id="card-img">
    <img src={img3} alt="..." />
    <div className="card-title text-center py-2">
    </div>
  </div>
</div>
 <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img src={img8} alt="..." />
              <div className="card-title text-center py-2">
               
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img src={img9} alt="..." />
              <div className="card-title text-center py-2">
               
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img src={img10} alt="..." />
              <div className="card-title text-center py-2">
               
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img src={img11} alt="..." />
              <div className="card-title text-center py-2">
               
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img src={img12} alt="..." />
              <div className="card-title text-center py-2">
               
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img src={img13} alt="..." />
              <div className="card-title text-center py-2">
               
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img src={img14} alt="..." />
              <div className="card-title text-center py-2">
               
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img src={img15} alt="..." />
              <div className="card-title text-center py-2">
               
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img src={img17} alt="..." />
              <div className="card-title text-center py-2">
               
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img src={img18} alt="..." />
              <div className="card-title text-center py-2">
               
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img src={img19} alt="..." />
              <div className="card-title text-center py-2">
               
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img src={img20} alt="..." />
              <div className="card-title text-center py-2">
               
              </div>
            </div>
          </div> */
}
